import React from 'react';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Title } from 'components/Title';
import { FacebookIcon, HouseIcon, LetterIcon, WhatsUpIcon } from 'assets/icons';
import { Booker } from 'useApp';

type Props = {
  booker: Booker;
};

const useStyles = makeStyles(theme => ({
  list: {
    marginTop: theme.spacing(2),
  },
  rootIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(1.5),
  },
  primaryText: {
    fontSize: 18,
  },
}));

export const Contacts = ({ booker }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Title value="Contacts" />
      <List className={classes.list}>
        <ListItem disableGutters>
          <ListItemIcon
            classes={{
              root: classes.rootIcon,
            }}
          >
            <WhatsUpIcon />
          </ListItemIcon>
          <ListItemText
            primary={booker.whatsApp}
            classes={{
              primary: classes.primaryText,
            }}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon
            classes={{
              root: classes.rootIcon,
            }}
          >
            <FacebookIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link
                href={booker.facebook}
                target="_blank"
              >{`${booker.firstName} ${booker.lastName}`}</Link>
            }
            classes={{
              primary: classes.primaryText,
            }}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon
            classes={{
              root: classes.rootIcon,
            }}
          >
            <LetterIcon />
          </ListItemIcon>
          <ListItemText
            primary={booker.email}
            classes={{
              primary: classes.primaryText,
            }}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon
            classes={{
              root: classes.rootIcon,
            }}
          >
            <HouseIcon />
          </ListItemIcon>
          <ListItemText
            primary={booker.location || 'Ukraine, Kyiv'}
            classes={{
              primary: classes.primaryText,
            }}
          />
        </ListItem>
      </List>
    </Box>
  );
};
