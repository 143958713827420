import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Content, Logo, Nav } from 'components';

type Props = {
  opacity?: number;
  isVideos?: boolean;
};

const useStyles = makeStyles({
  wrapper: {
    opacity: ({ opacity }: { opacity: number }) => opacity,
  },
});

export const Header = ({ opacity = 1, isVideos = false }: Props) => {
  const classes = useStyles({ opacity });

  return (
    <Box className={classes.wrapper}>
      <Content py={false}>
        <Box
          height={170}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Logo />
        </Box>
      </Content>
      <Nav isVideos={isVideos} />
    </Box>
  );
};
