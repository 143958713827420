import React from 'react';
import classNames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';

type Props = {
  value: string;
  fontRegular?: boolean;
  uppercase?: boolean;
  pointer?: boolean;
};

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  fontRegular: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const Title = ({
  value,
  fontRegular = false,
  uppercase = false,
  pointer = false,
}: Props) => {
  const classes = useStyles();

  return (
    <Typography
      variant="h3"
      className={classNames(
        classes.title,
        pointer && classes.pointer,
        fontRegular && classes.fontRegular,
      )}
    >
      {uppercase ? value.toUpperCase() : value}
    </Typography>
  );
};
