import React from 'react';
import classNames from 'classnames';
import {
  makeStyles,
  Box,
  List,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { MobileMenu } from './MobileMenu';
import { MenuItems } from './MenuItems';

type Props = {
  isVideos?: boolean;
};

const useStyles = makeStyles(theme => ({
  listWrapper: {
    display: 'flex',
    backgroundColor: '#eeeeee',
    justifyContent: 'center',
  },
  mobileListWrapper: {
    justifyContent: 'flex-start',
  },
  list: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,

    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'none',
      },
    },

    '& li': {
      width: 'auto',
      transition: 'background-color 0.5s ease',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#cccccc',
        textDecoration: 'none',
      },
    },
  },
}));

export const Nav = ({ isVideos = false }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      className={classNames(
        classes.listWrapper,
        mobileMediaQuery && classes.mobileListWrapper,
      )}
    >
      {mobileMediaQuery ? (
        <Box pl={3} width="100%" position="relative">
          <MobileMenu>
            <MenuItems isVideos={isVideos} className={classes.list} />
          </MobileMenu>
        </Box>
      ) : (
        <List className={classes.list}>
          <MenuItems isVideos={isVideos} className={classes.list} />
        </List>
      )}
    </Box>
  );
};
