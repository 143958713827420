import React from 'react';
import Slider from 'react-slick';
import { Box, makeStyles } from '@material-ui/core';
import { NextArrow, PrevArrow } from 'components/Arrows';

type Props = {
  videoUrls: Array<{ code: string }>;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',

    '& .slick-list': {
      margin: theme.spacing(0, 4),
    },

    '& .slick-slide': {
      display: 'flex',
      justifyContent: 'center',

      '& div[tabindex]': {
        [theme.breakpoints.down('xl')]: {
          maxWidth: 843,
          maxHeight: 476,
        },
        [theme.breakpoints.down('lg')]: {
          maxWidth: 743,
          maxHeight: 376,
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: 630,
          maxHeight: 365,
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: 430,
          maxHeight: 265,
        },
        [theme.breakpoints.down('xs')]: {
          maxWidth: 250,
          maxHeight: 180,
        },
      },
    },

    '& iframe': {
      width: '100%',
      height: '100%',
    },

    '& .slick-dots li button:before': {
      fontSize: '0.6rem',
    },
  },
  container: {
    [theme.breakpoints.down('xl')]: {
      width: 843,
      height: 476,
    },
    [theme.breakpoints.down('lg')]: {
      width: 743,
      height: 376,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 630,
      maxHeight: 365,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 430,
      maxHeight: 265,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 250,
      maxHeight: 180,
    },
  },
  box: {
    [theme.breakpoints.down('xl')]: {
      width: 1000,
    },
    [theme.breakpoints.down('lg')]: {
      width: 750,
    },
    [theme.breakpoints.down('sm')]: {
      width: 600,
    },
    [theme.breakpoints.down('xs')]: {
      width: 320,
    },
  },
}));

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
      },
    },
  ],
};

export const VideoGallery = ({ videoUrls }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.box}>
        <Slider {...(settings as any)} key="video-slider">
          {videoUrls.map((videoUrl, index) => (
            // eslint-disable-next-line react/no-danger
            <Box key={`${videoUrl.code.length}-${index + 1}`}>
              <Box
                className={classes.container}
                dangerouslySetInnerHTML={{ __html: videoUrl.code }}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};
