import React from 'react';
import classNames from 'classnames';
import { NextArrowIcon, PrevArrowIcon } from 'assets/icons';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  icon: {
    width: 31,
    height: 82,
  },
});

export const NextArrow = ({ className, style, onClick }: any) => {
  const classes = useStyles();

  return (
    <PrevArrowIcon
      className={classNames(className, classes.icon)}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

export const PrevArrow = ({ className, style, onClick }: any) => {
  const classes = useStyles();

  return (
    <NextArrowIcon
      className={classNames(className, classes.icon)}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};
