import { useEffect, useState } from 'react';
import { getDemoPage } from 'config/api';
import { Image } from 'components/Photos/Gallery';

type Properties = {
  height?: string;
  bust?: string;
  waist?: string;
  hips?: string;
  shoe?: string;
  hair?: string;
  eyes?: string;
};

export type Model = {
  id: string;
  mainGallery?: Array<Image>;
  polaroidGallery?: Array<Image>;
  videos?: Array<{ code: string }>;
  firstName: string;
  lastName: string;
  birthday?: string;
  age?: string;
  gender: string;
  city?: string;
  country?: string;
  instagram?: string;
  properties: Properties;
  image?: Image;
};

export type Booker = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  whatsApp: string;
  facebook: string;
  location?: string;
};

type Data = {
  model: Model;
  booker: Booker;
};

type Initial = {
  status: 'initial';
};

type Loading = {
  status: 'loading';
};

type Success = {
  status: 'success';
  data: Data;
};

type Error = {
  status: 'error';
  error: string;
};

type State = Initial | Loading | Success | Error;

const getParams = () => {
  const { pathname } = window.location;

  const [modelId, bookerId] = pathname.split('/').filter(param => param !== '');

  return {
    modelId,
    bookerId,
  };
};

export const useApp = () => {
  const [state, setState] = useState<State>({ status: 'initial' });
  const { modelId, bookerId } = getParams();

  useEffect(() => {
    setState({ status: 'loading' });

    fetch(getDemoPage, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        modelId,
        bookerId,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.errors) {
          return setState({ status: 'error', error: data.errors[0].message });
        }

        setState({ status: 'success', data });
      })
      .catch(error => {
        setState({ status: 'error', error });
      });
  }, []);

  return { state };
};
