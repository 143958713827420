import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Content } from 'components/Content';
import Tabs, { Tab } from './Tabs';
import { Portfolio } from './Portfolio';
import { Digitals } from './Digitals';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Image } from './Gallery';

type Props = {
  main?: Array<Image>;
  digitals?: Array<Image>;
};

const tabs: Array<Tab> = [{ label: 'Portfolio' }, { label: 'Digitals' }];

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.down('xl')]: {
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
    },
  },
}));

export const Photos = ({ main = [], digitals = [] }: Props) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <Content contained id="photos">
      <Box display="flex" justifyContent="center">
        <Tabs tabs={tabs} active={activeTab} onChange={setActiveTab} />
      </Box>
      <Box className={classes.wrapper}>
        {activeTab.label === 'Portfolio' ? (
          <Portfolio data={main} />
        ) : (
          <Digitals data={digitals} />
        )}
      </Box>
    </Content>
  );
};
