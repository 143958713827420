import React, { useState, ChangeEvent, useMemo } from 'react';
import { Box, TextField, Button, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Title } from 'components/Title';
import { sendFeedback } from 'config/api';

type Props = {
  bookerId: string;
};

type Request = {
  titleForm: string;
  emailForm: string;
  messageForm: string;
};

const useStyles = makeStyles(theme => ({
  btn: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',

      '& button': {
        width: 180,
        height: 45,
        fontSize: '1rem',
      },
    },
  },
}));

const validations = {
  name: {
    maxLength: 100,
  },
  email: {
    regex: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
    maxLength: 100,
  },
  message: {
    maxLength: 1000,
  },
};

export const Feedback = ({ bookerId }: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [loading, setLoading] = useState(false);

  const disabled = useMemo(
    () =>
      loading ||
      nameError.length > 0 ||
      emailError.length > 0 ||
      messageError.length > 0,
    [loading, nameError, emailError, messageError],
  );

  const handleValidateName = (value: string) => {
    if (name.length === 0) {
      setNameError('Required field!');
    } else if (value.length > validations.name.maxLength) {
      setNameError('Max length 100 characters!');
    } else {
      setNameError('');
    }
  };

  const handleValidateEmail = (value: string) => {
    if (email.length === 0) {
      setEmailError('Required field!');
    } else if (!validations.email.regex.test(value)) {
      setEmailError('Not valid email!');
    } else if (value.length > validations.email.maxLength) {
      setEmailError('Max length 100 characters!');
    } else {
      setEmailError('');
    }
  };

  const handleValidateMessage = (value: string) => {
    if (message.length === 0) {
      setMessageError('Required field!');
    } else if (value.length > validations.message.maxLength) {
      setMessageError('Max length 500 characters!');
    } else {
      setMessageError('');
    }
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setName(value);
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setEmail(value);
  };

  const handleRequest = ({ titleForm, emailForm, messageForm }: Request) => {
    setLoading(true);

    window
      .fetch(sendFeedback, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bookerId,
          title: titleForm,
          email: emailForm,
          message: messageForm,
        }),
      })
      .then(res => {
        if (res.status === 204) {
          enqueueSnackbar(`Message successfully sent!`, {
            variant: 'success',
          });

          setName('');
          setEmail('');
          setMessage('');
        }

        setLoading(false);
      })
      .catch(error => {
        enqueueSnackbar(`${error}`, {
          variant: 'error',
        });

        setLoading(false);
      });
  };

  const handleChangeMessage = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setMessage(value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (name.length === 0) {
      return setNameError('Required field!');
    }

    if (name.length > validations.name.maxLength) {
      return setNameError('Max length 100 characters');
    }

    setNameError('');

    if (email.length === 0) {
      return setEmailError('Required field!');
    }

    if (!validations.email.regex.test(email)) {
      return setEmailError('Not valid email!');
    }

    if (email.length > validations.email.maxLength) {
      return setEmailError('Max length 100 characters!');
    }

    setEmailError('');

    if (email.length === 0) {
      return setMessageError('Required field!');
    }

    if (message.length > validations.message.maxLength) {
      return setMessageError('Max length 500 characters!');
    }

    setMessageError('');

    handleRequest({ titleForm: name, emailForm: email, messageForm: message });
  };

  return (
    <Box>
      <Title value="Your answer" />
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" pt={2}>
          <Box
            width={{
              md: '100%',
              lg: '65%',
            }}
          >
            <TextField
              value={name}
              onChange={handleChangeName}
              onBlur={e => handleValidateName(e.target.value)}
              label="Name"
              fullWidth
              error={nameError.length > 0}
              helperText={nameError}
            />
          </Box>
          <Box
            width={{
              md: '100%',
              lg: '65%',
            }}
            pt={1}
          >
            <TextField
              value={email}
              onChange={handleChangeEmail}
              onBlur={e => handleValidateEmail(e.target.value)}
              label="E-mail"
              fullWidth
              error={emailError.length > 0}
              helperText={emailError}
            />
          </Box>
          <Box width="100%" pt={1}>
            <TextField
              value={message}
              onChange={handleChangeMessage}
              onBlur={e => handleValidateMessage(e.target.value)}
              label="Message"
              multiline
              rows={5}
              rowsMax={10}
              fullWidth
              error={messageError.length > 0}
              helperText={messageError}
            />
          </Box>
          <Box className={classes.btn}>
            <Button type="submit" color="secondary" disabled={disabled}>
              Send
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
