import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';

type Props = {
  children: ReactNode;
};

export const Wrapper = ({ children }: Props) => (
  <Box component="main" display="flex" flexDirection="column">
    {children}
  </Box>
);
