import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Content } from 'components/Content';
import { Title } from 'components/Title';
import { VideoGallery } from './VideoGallery';

type Props = {
  videos?: Array<{ code: string }>;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.down('xl')]: {
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
}));

export const Videos = ({ videos = [] }: Props) => {
  const classes = useStyles();

  return (
    <Content id="videos">
      {videos.length > 0 && (
        <>
          <Box display="flex" justifyContent="center">
            <Title value="Videos" uppercase />
          </Box>
          <Box className={classes.wrapper}>
            <VideoGallery videoUrls={videos} />
          </Box>
        </>
      )}
    </Content>
  );
};
