import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const LetterIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 19 13" fill="none" {...props}>
    <path
      d="M9.6 0.0752783C12.128 0.0752783 14.66 0.0752783 17.2 0.0752783C18.728 0.0752783 19.2 0.513627 19.2 1.89832C19.2 4.90257 19.2 7.9 19.2 10.8906C19.2 12.2056 18.708 12.6481 17.2 12.6522H1.956C0.484 12.6481 0 12.2015 0 10.8701C0 7.86586 0 4.8616 0 1.85735C0 0.48495 0.472 0.067085 2.028 0.0629883C4.556 0.067085 7.088 0.0752783 9.6 0.0752783ZM6.836 6.26951C5.2 7.646 3.636 8.94056 2.096 10.272C1.736 10.5792 0.936 10.8783 1.528 11.4559C2.12 12.0336 2.52 11.3658 2.892 11.0463C4.492 9.72303 6.092 8.37522 7.748 6.99053C8.172 7.32646 8.548 7.61323 8.916 7.92458C9.00369 8.02255 9.11031 8.10079 9.22909 8.15431C9.34788 8.20783 9.47623 8.23546 9.606 8.23546C9.73577 8.23546 9.86413 8.20783 9.98291 8.15431C10.1017 8.10079 10.2083 8.02255 10.296 7.92458C10.696 7.58045 11.12 7.2773 11.544 6.94956L15.7 10.4891C16.0467 10.8263 16.4215 11.132 16.82 11.4027C17.1082 11.4879 17.4094 11.5171 17.708 11.4887C17.7064 11.198 17.6674 10.9087 17.592 10.6284C17.528 10.4604 17.284 10.3498 17.12 10.2187L12.4 6.21625C12.656 6.0278 12.876 5.87212 13.08 5.70826C14.548 4.5325 16.028 3.36494 17.456 2.15641C17.676 1.97206 17.856 1.46816 17.72 1.2879C17.42 0.84546 17 1.09946 16.668 1.36984L12.524 4.72505L9.6 7.03559L4.72 3.12323C3.948 2.50053 3.2 1.85735 2.4 1.27561C2.208 1.14042 1.816 1.23055 1.512 1.21416C1.52599 1.5033 1.56888 1.7902 1.64 2.07038C1.692 2.21376 1.92 2.30799 2.064 2.42679L6.836 6.26951Z"
      fill="#333333"
    />
  </SvgIcon>
);
