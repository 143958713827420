import React, { useContext } from 'react';
import { List, ListItem, Link } from '@material-ui/core';
// @ts-ignore
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { MenuContext } from '../menuContext';

type Props = {
  className: string;
  isVideos?: boolean;
};

export const MenuItems = ({ className, isVideos = false }: Props) => {
  const { onClose } = useContext(MenuContext);

  return (
    <List className={className}>
      <AnchorLink href="#model-info">
        <ListItem onClick={onClose}>Model info</ListItem>
      </AnchorLink>

      <AnchorLink href="#photos">
        <ListItem onClick={onClose}>Photos </ListItem>
      </AnchorLink>

      {isVideos && (
        <AnchorLink href="#videos">
          <ListItem onClick={onClose}>Videos </ListItem>
        </AnchorLink>
      )}

      <AnchorLink href="#contacts">
        <ListItem onClick={onClose}>Contacts</ListItem>
      </AnchorLink>

      <Link href="http://www.foxmodel.com.ua" target="_blank">
        <ListItem>Model Agency</ListItem>
      </Link>
    </List>
  );
};
