import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Content } from 'components/Content';
import { Booker } from 'useApp';
import { Contacts } from './Contacts';
import { Feedback } from './Feedback';
import { FollowUs } from './FollowUs';

type Props = {
  booker: Booker;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',

    [theme.breakpoints.down('xl')]: {
      justifyContent: 'space-between',
    },

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },

    '& > *:not(:last-child)': {
      paddingRight: theme.spacing(4),
    },

    '& > *:nth-child(1)': {
      order: 1,
      maxWidth: 325,
      wordBreak: 'break-all',

      [theme.breakpoints.down('sm')]: {
        order: 2,
      },
    },

    '& > *:nth-child(2)': {
      minWidth: 400,
      order: 2,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        order: 1,
        padding: theme.spacing(3, 0, 10, 0),
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },

    '& > *:nth-child(3)': {
      order: 3,
    },
  },
}));

export const Footer = ({ booker }: Props) => {
  const classes = useStyles();

  return (
    <Content contained id="contacts">
      <Box className={classes.wrapper}>
        <Contacts booker={booker} />
        <Feedback bookerId={booker.id} />
        <FollowUs />
      </Box>
    </Content>
  );
};
