import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const FacebookIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 30 30" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.96 0.546021H23.04C24.72 0.557104 26.3276 1.23187 27.5122 2.42323C28.6968 3.6146 29.3624 5.22594 29.364 6.90602V23.106C29.3577 24.7835 28.6902 26.3909 27.5063 27.5793C26.3223 28.7678 24.7175 29.4414 23.04 29.454H6.96C5.27739 29.4445 3.66712 28.7686 2.48182 27.5743C1.29652 26.38 0.632796 24.7647 0.635998 23.082V6.88202C0.642297 5.20562 1.31022 3.59951 2.49449 2.41298C3.67877 1.22646 5.28362 0.555498 6.96 0.546021Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.38 11.724V10.326C16.3667 10.2193 16.377 10.1109 16.41 10.0085C16.4431 9.90612 16.4981 9.8122 16.5713 9.73331C16.6445 9.65443 16.734 9.5925 16.8336 9.55186C16.9332 9.51122 17.0405 9.49285 17.148 9.49805H19.092V6.49805H16.41C13.446 6.49805 12.78 8.70605 12.78 10.134V11.706H11.058V15.186H12.792V23.892H16.254V15.204H18.816L18.93 13.836L19.134 11.724H16.38Z"
      fill="#FEFEFE"
    />
  </SvgIcon>
);
