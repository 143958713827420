import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 480,
      sm: 780,
      md: 980,
      lg: 1200,
      xl: 1500,
    },
  },
  typography: {
    fontFamily: '"Roboto", "Arial", "sans serif"',
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h3: {
      fontSize: '1.75rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    body2: {
      fontWeight: 700,
    },
    caption: {
      fontWeight: 500,
      lineHeight: '1.125rem',
    },
  },
  palette: {
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#750B0B',
    },
    text: {
      primary: '#333333',
      secondary: '#4e5866',
    },
    warning: {
      main: '#fa6400',
    },
    divider: '#DFE3E8',
    error: {
      main: '#a90000',
    },
    background: {
      default: '#eeeeee',
    },
  },
  overrides: {
    MuiTypography: {
      caption: {
        color: '#4e5866',
      },
    },
    MuiButton: {
      root: {
        minWidth: 100,
        textTransform: 'none',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
      },
    },
    MuiSelect: {
      root: {
        fontSize: 14,
      },
    },
    MuiInputBase: {
      input: {
        // https://github.com/mui-org/material-ui/issues/14427#issuecomment-466054906
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
  },
  props: {
    MuiButton: {
      children: 'Button',
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiButtonGroup: {
      disableElevation: true,
    },
    MuiRadio: {
      color: 'primary',
    },
  },
});
