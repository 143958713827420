type SocialButtons = {
  vk: string;
  youtube: string;
  instagram: string;
  facebook: string;
};

export const socialButtons: SocialButtons = {
  vk: 'https://vk.com/foxmodelmanagement',
  youtube: 'https://www.youtube.com/channel/UCvTMMuhb0_5UGICcxfs1k0g',
  instagram: 'https://www.instagram.com/fmm_foxmodelmanagement/',
  facebook: 'https://www.facebook.com/foxmodelmanagement/',
};
