import React from 'react';
import { Box, Typography, Link, makeStyles } from '@material-ui/core';
import { Content } from 'components/Content';
import { Model } from 'useApp';
import { getName } from 'helpers/getName';
import avatar from 'assets/images/avatar.png';
import { Property } from './Property';

type Props = {
  model: Model;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingBottom: theme.spacing(3),
    },
  },
  imageWrapper: {
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 30),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 15),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 6),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(6, 0),
    },
  },
  modelName: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  image: {
    objectFit: 'cover',

    [theme.breakpoints.down('xl')]: {
      width: 370,
      height: 520,
    },
    [theme.breakpoints.down('lg')]: {
      width: 330,
      height: 440,
    },
    [theme.breakpoints.down('sm')]: {
      width: 280,
      height: 389,
    },
  },
  properties: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },

    '& *:not(:first-child)': {
      paddingTop: theme.spacing(2),
    },
  },
}));

const getInstagramName = (str: string) => {
  const index = str.indexOf('.com/');
  const name = str.slice(index + 5);

  return `@${name}`;
};

export const ModelInfo = ({ model }: Props) => {
  const classes = useStyles();

  return (
    <Content id="model-info">
      <Box className={classes.wrapper}>
        <Box
          textAlign={{
            xs: 'center',
            sm: 'right',
          }}
        >
          <Typography variant="h3" className={classes.modelName}>
            {`${model.firstName} ${model.lastName}`}
          </Typography>
          {model.instagram && (
            <Box pt={2}>
              <Link href={model.instagram} target="_blank">
                <Typography variant="h5">
                  {getInstagramName(model.instagram)}
                </Typography>
              </Link>
            </Box>
          )}
        </Box>
        <Box className={classes.imageWrapper}>
          <img
            src={model.image?.transformations.medium || avatar}
            alt={`${model.firstName} ${model.lastName}`}
            title={`${model.firstName} ${model.lastName}`}
            className={classes.image}
          />
        </Box>
        <Box className={classes.properties}>
          <Property title="Age" value={getName(model.age)} />
          <Property title="Height" value={getName(model.properties.height)} />
          <Property title="Bust" value={getName(model.properties.bust)} />
          <Property title="Waist" value={getName(model.properties.waist)} />
          <Property title="Hips" value={getName(model.properties.hips)} />
          <Property title="Hair" value={getName(model.properties.hair)} />
          <Property title="Shoes" value={getName(model.properties.shoe)} />
        </Box>
      </Box>
    </Content>
  );
};
