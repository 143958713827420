import React, { ReactNode, useState } from 'react';
import {
  makeStyles,
  Box,
  IconButton,
  ClickAwayListener,
  Typography,
} from '@material-ui/core';
import { CloseIcon, MenuIcon } from 'assets/icons';
import { MenuContext } from '../menuContext';

const useStyles = makeStyles(theme => ({
  mobileWrapper: {
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 48,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: 0,
    border: '1px solid #eeeeee',

    '& ul': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },

    '& a': {
      width: '100%',
    },
  },
}));

export const MobileMenu = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(prevState => !prevState);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <IconButton color="primary" component="span" onClick={handleClick}>
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Box pl={1}>
          <Typography variant="h5">Menu</Typography>
        </Box>
      </Box>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <Box className={classes.mobileWrapper}>
            <MenuContext.Provider
              value={{
                onClose: handleClose,
              }}
            >
              {children}
            </MenuContext.Provider>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};
