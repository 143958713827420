import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Wrapper,
  Header,
  ModelInfo,
  Photos,
  Videos,
  Footer,
  NotFoundPage,
  Loading,
} from 'components';
import { useApp } from 'useApp';

const App = () => {
  const { state } = useApp();

  switch (state.status) {
    case 'loading': {
      return (
        <>
          <Helmet title="Loading..." />
          <Wrapper>
            <Header opacity={0.5} />
            <Loading />
          </Wrapper>
        </>
      );
    }
    case 'success': {
      return (
        <>
          <Helmet
            title={`${state.data.model.firstName} ${state.data.model.lastName} - Foxmodel management`}
          />
          <Wrapper>
            <Header
              isVideos={
                state.data.model?.videos?.length !== undefined &&
                state.data.model?.videos?.length > 0
              }
            />
            <ModelInfo model={state.data.model} />
            <Photos
              main={state.data.model?.mainGallery}
              digitals={state.data.model?.polaroidGallery}
            />
            <Videos videos={state.data.model?.videos} />
            <Footer booker={state.data.booker} />
          </Wrapper>
        </>
      );
    }
    case 'error': {
      return (
        <>
          <Helmet title="404 - Not Found Page" />
          <NotFoundPage />
        </>
      );
    }
    default: {
      return <div>...</div>;
    }
  }
};

export default App;
