import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const YouTubeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 30 22" fill="none" {...props}>
    <path
      d="M29.262 7.25602C29.262 5.58834 28.5995 3.98896 27.4203 2.80973C26.2411 1.6305 24.6417 0.968018 22.974 0.968018H6.88201C5.21536 0.969608 3.61753 1.63279 2.4396 2.81185C1.26167 3.99091 0.600005 5.58938 0.600006 7.25602V14.744C0.600005 16.4107 1.26167 18.0091 2.4396 19.1882C3.61753 20.3672 5.21536 21.0304 6.88201 21.032H22.974C24.6417 21.032 26.2411 20.3695 27.4203 19.1903C28.5995 18.0111 29.262 16.4117 29.262 14.744V7.25602ZM19.8 11.564L12.6 15.128C12.318 15.284 11.358 15.08 11.358 14.756V7.43002C11.358 7.10602 12.324 6.90202 12.606 7.06402L19.5 10.82C19.8 11 20.094 11.402 19.8 11.564Z"
      fill="#333333"
    />
  </SvgIcon>
);
