import React from 'react';
import { Box, Link, makeStyles } from '@material-ui/core';
import { LogoIcon } from 'assets/icons/LogoIcon';

type Props = {
  maxWidth?: number;
  maxHeight?: number;
};

type PropsStyles = {
  maxWidth: number;
  maxHeight: number;
};

const useStyles = makeStyles({
  wrapper: {
    maxWidth: ({ maxWidth }: PropsStyles) => maxWidth,
    maxHeight: ({ maxHeight }: PropsStyles) => maxHeight,
  },
  logoIcon: {
    width: '100%',
    height: '100%',
  },
});

export const Logo = ({ maxWidth = 272, maxHeight = 125 }: Props) => {
  const classes = useStyles({ maxWidth, maxHeight });

  return (
    <Link href="http://foxmodel.com.ua" target="_blank">
      <Box className={classes.wrapper}>
        <LogoIcon className={classes.logoIcon} />
      </Box>
    </Link>
  );
};
