import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const InstagramIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 30 30" fill="none" {...props}>
    <path
      d="M22.65 5.71205C22.3137 5.71086 21.9846 5.80959 21.7044 5.99571C21.4243 6.18183 21.2057 6.44696 21.0764 6.75747C20.9472 7.06799 20.9131 7.40989 20.9784 7.73983C21.0437 8.06977 21.2055 8.37287 21.4434 8.6107C21.6812 8.84853 21.9843 9.01038 22.3143 9.0757C22.6442 9.14103 22.9861 9.1069 23.2966 8.97764C23.6071 8.84838 23.8723 8.62981 24.0584 8.34966C24.2445 8.0695 24.3432 7.74039 24.342 7.40405C24.342 6.9553 24.1638 6.52493 23.8465 6.20762C23.5291 5.89031 23.0988 5.71205 22.65 5.71205Z"
      fill="#333333"
    />
    <path
      d="M15.12 7.88403C13.7126 7.88403 12.3368 8.30138 11.1666 9.08329C9.99634 9.86521 9.08427 10.9766 8.54567 12.2769C8.00708 13.5771 7.86616 15.0079 8.14073 16.3883C8.41531 17.7687 9.09304 19.0366 10.0882 20.0318C11.0834 21.027 12.3514 21.7047 13.7317 21.9793C15.1121 22.2539 16.5429 22.113 17.8432 21.5744C19.1435 21.0358 20.2548 20.1237 21.0367 18.9535C21.8187 17.7832 22.236 16.4074 22.236 15C22.2344 13.1132 21.4842 11.3042 20.15 9.97002C18.8159 8.63585 17.0068 7.88562 15.12 7.88403ZM15.12 19.56C14.2179 19.5612 13.3356 19.2948 12.585 18.7945C11.8343 18.2941 11.2489 17.5824 10.9028 16.7492C10.5568 15.9161 10.4656 14.9991 10.6409 14.1141C10.8162 13.2292 11.25 12.4161 11.8875 11.7778C12.525 11.1394 13.3375 10.7045 14.2222 10.5281C15.1069 10.3517 16.0241 10.4416 16.8576 10.7866C17.6912 11.1315 18.4038 11.716 18.9051 12.466C19.4064 13.216 19.674 14.0979 19.674 15C19.6724 16.2079 19.1923 17.3659 18.3388 18.2206C17.4853 19.0752 16.3279 19.5569 15.12 19.56Z"
      fill="#333333"
    />
    <path
      d="M20.766 29.448H9.234C6.93188 29.4464 4.7245 28.5312 3.09666 26.9033C1.46881 25.2755 0.553591 23.0681 0.552002 20.766V9.234C0.553591 6.93188 1.46881 4.7245 3.09666 3.09666C4.7245 1.46881 6.93188 0.553591 9.234 0.552002H20.766C23.0681 0.553591 25.2755 1.46881 26.9033 3.09666C28.5312 4.7245 29.4464 6.93188 29.448 9.234V20.766C29.4464 23.0681 28.5312 25.2755 26.9033 26.9033C25.2755 28.5312 23.0681 29.4464 20.766 29.448ZM9.234 3.27C7.65517 3.27945 6.14368 3.91083 5.02725 5.02725C3.91083 6.14368 3.27945 7.65517 3.27 9.234V20.766C3.27945 22.3448 3.91083 23.8563 5.02725 24.9728C6.14368 26.0892 7.65517 26.7206 9.234 26.73H20.766C22.3448 26.7206 23.8563 26.0892 24.9728 24.9728C26.0892 23.8563 26.7206 22.3448 26.73 20.766V9.234C26.7206 7.65517 26.0892 6.14368 24.9728 5.02725C23.8563 3.91083 22.3448 3.27945 20.766 3.27H9.234Z"
      fill="#333333"
    />
  </SvgIcon>
);
