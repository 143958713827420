import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

type Props = {
  title: string;
  value: string;
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const Property = ({ title, value }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h5">
        <span className={classes.bold}>{title}:</span> {value}
      </Typography>
    </Box>
  );
};
