import React, { useMemo } from 'react';
import Slider from 'react-slick';
import { Box, makeStyles } from '@material-ui/core';
import { PrevArrow, NextArrow } from 'components/Arrows';

export type Image = {
  id: string;
  transformations: {
    xsmall: string;
    small: string;
    medium: string;
    large: string;
  };
};

type Props = {
  imageUrls: Array<Image>;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& .slick-list': {
      margin: theme.spacing(0, 4),
    },
    '& .slick-slide': {
      display: 'flex',
      justifyContent: 'center',

      '& div[tabindex]': {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    '& img': {
      objectFit: 'cover',
      padding: theme.spacing(0, 3),

      [theme.breakpoints.down('xl')]: {
        width: 396,
        height: 592,
      },
      [theme.breakpoints.down('lg')]: {
        width: 296,
        height: 440,
      },
      [theme.breakpoints.down('md')]: {
        width: 342,
        height: 488,
      },
      [theme.breakpoints.down('sm')]: {
        width: 280,
        height: 389,
      },
      [theme.breakpoints.down('xs')]: {
        width: 220,
        height: 330,
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },

    '& .slick-dots li button:before': {
      fontSize: '0.6rem',
    },
  },
}));

export const Gallery = ({ imageUrls }: Props) => {
  const classes = useStyles();

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: imageUrls.length > 3,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      swipeToSlide: true,
      nextArrow: <PrevArrow />,
      prevArrow: <NextArrow />,
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            infinity: imageUrls.length > 2,
            slidesToShow: 2,
            lazyLoad: true,
          },
        },
        {
          breakpoint: 780,
          settings: {
            infinity: imageUrls.length > 1,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            lazyLoad: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            infinity: imageUrls.length > 1,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            autoplay: true,
            lazyLoad: true,
          },
        },
      ],
    }),
    [],
  );

  return (
    <Box className={classes.wrapper}>
      <Slider {...(settings as any)} key="images-slider">
        {imageUrls.map((imageUrl, index) => (
          <div key={`${imageUrl.id}-${index + 1}`}>
            <img
              src={`${imageUrl.transformations.medium}`}
              alt={`Photo ${index + 1}`}
            />
          </div>
        ))}
      </Slider>
    </Box>
  );
};
