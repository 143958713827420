import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const HouseIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 21" fill="none" {...props}>
    <path
      d="M0.367981 10.7158C0.523981 10.5315 0.667981 10.3266 0.835981 10.1587L4.45598 6.6191L7.25598 3.91527C7.86798 3.31306 8.47598 2.68626 9.09598 2.11272C9.21782 1.98786 9.36249 1.88881 9.52172 1.82123C9.68095 1.75365 9.85162 1.71887 10.024 1.71887C10.1963 1.71887 10.367 1.75365 10.5262 1.82123C10.6855 1.88881 10.8301 1.98786 10.952 2.11272C11.888 3.02219 12.816 3.93985 13.752 4.85342L13.836 4.92307C13.836 4.83294 13.836 4.77149 13.836 4.71413V2.34623C13.836 1.89969 13.952 1.7604 14.328 1.7604H16.4C16.752 1.7604 16.88 1.90789 16.88 2.32575C16.88 4.1283 16.88 5.92949 16.88 7.72931C16.8759 7.79225 16.886 7.85531 16.9096 7.91358C16.9332 7.97185 16.9696 8.02375 17.016 8.06524C17.816 8.82723 18.596 9.59332 19.38 10.3676C19.4741 10.4766 19.5597 10.593 19.636 10.7158V10.9698C19.384 11.3344 19.136 11.6949 18.88 12.0554C18.624 12.416 18.48 12.3955 18.196 12.1333C17.48 11.4368 16.768 10.7322 16.052 10.0317L14.16 8.18405L12.212 6.27908C11.512 5.59493 10.808 4.90668 10.112 4.23072C10.032 4.14879 9.97198 4.14879 9.88798 4.23072C9.25331 4.86161 8.61331 5.49114 7.96798 6.11931L6.05598 7.98741L4.13198 9.87189L1.99998 11.9448C1.91598 12.0268 1.83598 12.1087 1.75198 12.1824C1.71733 12.2256 1.67295 12.2595 1.62266 12.2811C1.57237 12.3028 1.51769 12.3115 1.46334 12.3067C1.409 12.3019 1.35662 12.2835 1.31073 12.2533C1.26485 12.2231 1.22683 12.1819 1.19998 12.1333C0.919981 11.7523 0.651981 11.359 0.379981 10.9698L0.367981 10.7158Z"
      fill="#333333"
    />
    <path
      d="M11.532 19.6958V14.3373H11.364H8.64002C8.50402 14.3373 8.46802 14.3782 8.46802 14.538C8.46802 16.1767 8.46802 17.8154 8.46802 19.4541V19.7285H4.00002C3.44402 19.7285 3.14002 19.3762 3.14002 18.7249C3.14002 16.5659 3.14002 14.4028 3.14002 12.2398C3.13703 12.1714 3.14957 12.1033 3.17664 12.0407C3.2037 11.9782 3.24457 11.9229 3.29602 11.8792L6.47202 8.80262L9.27202 6.09469C9.52002 5.85708 9.76402 5.61538 10.02 5.36548L11.128 6.43882L14.328 9.54412L16.8 11.9202C16.8568 11.9827 16.8895 12.0643 16.892 12.1496C16.892 14.3646 16.892 16.5809 16.892 18.7986C16.9036 18.9102 16.8927 19.023 16.86 19.13C16.8273 19.2371 16.7734 19.3361 16.7018 19.421C16.6302 19.5059 16.5423 19.5748 16.4436 19.6235C16.3449 19.6722 16.2375 19.6995 16.128 19.704C14.632 19.704 13.132 19.704 11.636 19.704L11.532 19.6958Z"
      fill="#333333"
    />
  </SvgIcon>
);
