import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const WhatsUpIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M1.32002 19.9804L2.43322 15.84C2.47597 15.6835 2.45708 15.5165 2.38042 15.3736C1.29326 13.2868 0.987821 10.8801 1.51921 8.58792C2.0506 6.29571 3.38382 4.26894 5.27827 2.87336C7.17273 1.47778 9.50363 0.805321 11.8503 0.977336C14.197 1.14935 16.405 2.15451 18.0757 3.81142C19.7464 5.46833 20.7698 7.66785 20.9612 10.013C21.1527 12.3582 20.4996 14.6946 19.1198 16.6006C17.7399 18.5065 15.7243 19.8565 13.4366 20.4069C11.1489 20.9573 8.73974 20.6718 6.64402 19.602C6.50782 19.5342 6.35238 19.5155 6.20402 19.5492L2.01082 20.68C1.91424 20.7066 1.81231 20.707 1.71553 20.6812C1.61874 20.6553 1.53061 20.6041 1.46022 20.5328C1.38983 20.4615 1.33973 20.3727 1.31509 20.2756C1.29044 20.1785 1.29214 20.0766 1.32002 19.9804V19.9804ZM6.68802 17.49L7.02242 17.6924C8.69776 18.7005 10.6841 19.0609 12.6068 18.7056C14.5296 18.3502 16.2559 17.3037 17.4602 15.7633C18.6645 14.2229 19.2636 12.2951 19.1446 10.3434C19.0255 8.39172 18.1966 6.55104 16.814 5.16843C15.4313 3.78582 13.5907 2.95683 11.639 2.83779C9.6873 2.71875 7.75951 3.31788 6.21911 4.5222C4.67871 5.72652 3.63216 7.45282 3.27681 9.37556C2.92145 11.2983 3.28184 13.2846 4.29002 14.96L4.49682 15.2988L3.78842 17.9388C3.7792 17.9727 3.77919 18.0085 3.78839 18.0424C3.79759 18.0764 3.81566 18.1073 3.84076 18.1319C3.86585 18.1566 3.89705 18.1741 3.93116 18.1827C3.96527 18.1912 4.00105 18.1906 4.03482 18.1808L6.68802 17.49Z"
      stroke="#333333"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M11.1848 18.788C15.5832 18.788 19.1488 15.2224 19.1488 10.824C19.1488 6.42559 15.5832 2.85999 11.1848 2.85999C6.78643 2.85999 3.22083 6.42559 3.22083 10.824C3.22083 15.2224 6.78643 18.788 11.1848 18.788Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.65957 15.0745L3.75317 18.2117L6.86837 17.3625L4.65957 15.0745Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7576 12.21C14.3528 11.968 13.8292 11.6996 13.3496 11.8932C12.9844 12.0428 12.7512 12.6148 12.5136 12.9096C12.4637 12.9754 12.392 13.0212 12.3114 13.039C12.2308 13.0567 12.1465 13.0452 12.0736 13.0064C10.7353 12.4809 9.61315 11.5199 8.88798 10.2784C8.82762 10.1995 8.79948 10.1007 8.80929 10.0018C8.81909 9.90296 8.8661 9.81153 8.94078 9.74603C9.20738 9.48488 9.40147 9.15887 9.50398 8.80003C9.52292 8.41427 9.43133 8.03113 9.23998 7.69563C9.05078 7.28203 8.83518 6.69243 8.42158 6.45923C8.22219 6.36731 8.00035 6.33541 7.78314 6.36745C7.56593 6.39948 7.36274 6.49406 7.19838 6.63963C6.9133 6.88024 6.68614 7.18203 6.53378 7.52254C6.38143 7.86306 6.3078 8.23354 6.31838 8.60643C6.33047 8.82084 6.36588 9.0333 6.42398 9.24003C6.56124 9.72248 6.7688 10.1821 7.03998 10.604C7.22822 10.9326 7.43537 11.2499 7.66038 11.5544C8.3858 12.5629 9.30037 13.4207 10.3532 14.08C10.8826 14.4115 11.4479 14.6816 12.0384 14.8852C12.6547 15.1618 13.3338 15.2682 14.0052 15.1932C14.3872 15.1347 14.7491 14.9839 15.0596 14.7539C15.3702 14.5239 15.6199 14.2216 15.7872 13.8732C15.8812 13.6621 15.9102 13.4278 15.8708 13.2C15.7652 12.7116 15.136 12.4344 14.7576 12.21Z"
      fill="white"
    />
  </SvgIcon>
);
