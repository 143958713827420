import React from 'react';
import { Box, Link, makeStyles } from '@material-ui/core';
import { VkIcon, YouTubeIcon, InstagramIcon, FacebookIcon } from 'assets/icons';
import { socialButtons } from 'config/socialButtons';

const useStyles = makeStyles(theme => ({
  list: {
    marginTop: theme.spacing(3),

    '& *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  icon: {
    width: 35,
    height: 35,
  },
}));

export const SocialButtons = () => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.list}>
      <Box>
        <Link href={socialButtons.vk} target="_blank">
          <VkIcon className={classes.icon} />
        </Link>
      </Box>
      <Box>
        <Link href={socialButtons.youtube} target="_blank">
          <YouTubeIcon className={classes.icon} />
        </Link>
      </Box>
      <Box>
        <Link href={socialButtons.instagram} target="_blank">
          <InstagramIcon className={classes.icon} />
        </Link>
      </Box>
      <Box>
        <Link href={socialButtons.facebook} target="_blank">
          <FacebookIcon className={classes.icon} />
        </Link>
      </Box>
    </Box>
  );
};
