import React from 'react';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import { SocialButtons } from 'components/SocialButtons';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  notFoundCode: {
    fontSize: '12rem',
    fontWeight: 300,

    [theme.breakpoints.down('sm')]: {
      fontSize: '8rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '5rem',
    },
  },
  subtitle: {
    fontWeight: 300,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
}));

export const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <Box position="relative" height="100vh">
      <Box className={classes.wrapper}>
        <Box>
          <Typography variant="h1" className={classes.notFoundCode}>
            404
          </Typography>
          <Typography variant="h3" className={classes.subtitle}>
            OOPS! NOTHING WAS FOUND
          </Typography>
        </Box>
        <Box pt={3}>
          <Divider />
        </Box>
        <Box display="flex" justifyContent="center">
          <SocialButtons />
        </Box>
      </Box>
    </Box>
  );
};
