import React from 'react';
import { Box, Divider, makeStyles } from '@material-ui/core';
import { Title } from 'components/Title';

export type Tab = {
  label: string;
};

type Props = {
  tabs: Array<Tab>;
  active: Tab;
  onChange: (tab: Tab) => void;
};

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.main,
  },
}));

const Tabs = ({ tabs, active, onChange }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex">
        {tabs.map((tab, index, arr) => (
          <Box
            key={tab.label}
            display="flex"
            alignItems="center"
            onClick={() => onChange(tab)}
          >
            <Title
              value={tab.label}
              fontRegular={active.label !== tab.label}
              uppercase
              pointer
            />
            {arr.length - 1 !== index && (
              <Divider orientation="vertical" className={classes.divider} />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Tabs;
