import React from 'react';
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  loader: {
    color: '#ababab',
  },
});

export const Loading = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt={5}
    >
      <CircularProgress size={100} thickness={5} className={classes.loader} />
      <Box mt={3}>
        <Typography variant="h3" color="textSecondary">
          Loading...
        </Typography>
      </Box>
    </Box>
  );
};
