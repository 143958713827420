import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Box, makeStyles } from '@material-ui/core';

type Props = {
  children: ReactNode;
  contained?: boolean;
  py?: boolean;
  id?: string;
};

const useStyles = makeStyles(theme => ({
  contained: {
    backgroundColor: theme.palette.background.default,
  },
  wrapper: {
    width: '100%',

    [theme.breakpoints.down('xl')]: {
      maxWidth: 1400,
      padding: ({ py }: { py: boolean }) => (py ? theme.spacing(12, 0) : 0),
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: 1100,
      padding: ({ py }: { py: boolean }) => (py ? theme.spacing(8, 0) : 0),
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 900,
      padding: ({ py }: { py: boolean }) => (py ? theme.spacing(6, 0) : 0),
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 700,
      padding: ({ py }: { py: boolean }) => (py ? theme.spacing(3, 0) : 0),
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 320,
      padding: ({ py }: { py: boolean }) => (py ? theme.spacing(5, 0) : 0),
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 320,
      padding: ({ py }: { py: boolean }) => (py ? theme.spacing(5, 0) : 0),
    },
  },
}));

export const Content = ({
  children,
  contained = false,
  id,
  py = true,
}: Props) => {
  const classes = useStyles({ py });

  return (
    <Box component="section" id={id}>
      <Box
        display="flex"
        justifyContent="center"
        className={classNames(contained ? classes.contained : null)}
      >
        <Box className={classes.wrapper}>{children}</Box>
      </Box>
    </Box>
  );
};
