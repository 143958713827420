import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Title } from 'components/Title';
import { SocialButtons } from 'components/SocialButtons';

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(5, 0, 3, 0),
    },
  },
}));

export const FollowUs = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Title value="Follow Us" />
      <SocialButtons />
    </Box>
  );
};
